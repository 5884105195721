import type React from 'react'
import Image from 'next/image'

import { FALLBACK_IMAGES } from '@cms/utils/constants'
import type { ContactProps } from '@knauf-group/ct-designs/components/core/Contact'
import {
  ContactsGallery,
  type ContactsGalleryProps,
} from '@knauf-group/ct-designs/components/core/ContactsGallery'
import type { ImageWeb } from '@knauf-group/ct-designs/utils/types'
import type {
  TypeContactSkeleton,
  TypeContentContactGallerySkeleton,
} from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'

const formatContactsEntry = ({
  sys,
  fields: contact,
}: ContentEntry<TypeContactSkeleton>): ContactProps => {
  const photo: ImageWeb = {
    url: contact?.photo?.[0].url,
    title: contact?.name,
    fallbackUrl: FALLBACK_IMAGES.CONTACTS,
  }
  return {
    id: sys.id,
    name: contact?.name,
    photo,
    nextImageComponent: Image,
    job: contact?.jobPosition,
    company: contact?.companyName,
    fax: contact?.fax,
    phone: contact?.telephone,
    email: contact?.email,
    address: contact?.address,
  }
}

const ContactsGalleryWrapper: React.FC<ContentEntry<TypeContentContactGallerySkeleton>> = ({
  fields: contactsGalleryContent,
}) => {
  if (!contactsGalleryContent) {
    return null
  }

  const contactsGalleryProps: ContactsGalleryProps = {
    headline: contactsGalleryContent?.headline,
    description: contactsGalleryContent?.text,
    contacts: contactsGalleryContent?.contacts
      ?.filter((contact) => contact.fields) // Filter out draft content from contentful
      .map((contact) => formatContactsEntry(contact)),
  }
  return <ContactsGallery {...contactsGalleryProps} />
}

export default ContactsGalleryWrapper
